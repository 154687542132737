import heroImage from "../assets/images/pages/SupportUs/Ortone.jpeg";
import Accordion from '../components/basics/Accordion';
import HeroicText from '../components/basics/HeroicText';
import BiColumnComponents from '../components/BiColumnComponents';
import { DatiFiscali } from '../utils/Constants';
import faqsJson from './../assets/files/jsons/faqs.json';
import './../css/components/faq-page.scss';

const faqHero = {
  title: "Contattami",
  body: "Vuoi una consulenza personalizzata per la tua idea?"
}



const contenutoContattiSide = (<>
  <div className='contatto'>
    <h2 className='nome-contatto'>email</h2>
    <a href={`mailto:${DatiFiscali.EMAIL}`}><p className='valore-contatto'>{DatiFiscali.EMAIL}</p></a>
  </div>
  <div className='contatto'>
    <h2 className='nome-contatto'>cellulare</h2>
    <a href={`callto:${DatiFiscali.CELLULARE}`}><p className='valore-contatto'>{DatiFiscali.CELLULARE}</p></a>
  </div>
</>
);



const Contact = () => {


  return (
    <main className="contatti">
      <HeroicText title={faqHero.title} subtitle={faqHero.body} paragraph={faqHero.p} arrow={true} img={heroImage}></HeroicText>
      <BiColumnComponents main={contenutoContattiSide} classes={"padded"} side={(<><h1>Le domande più richieste</h1><Accordion data={faqsJson} /></>)}></BiColumnComponents>

    </main>
  );

}

export default Contact;