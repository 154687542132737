import React from 'react';
import IntroductoryParagraph from "../components/basics/IntroductoryParagraph";
import SectionTitle from '../components/basics/SectionTitle.js';
import BiColumnComponents from '../components/BiColumnComponents.js';
import Timeline from '../components/Timeline.js';
const cooaExperience = [
    {
        data: "Dicembre 2019",
        evento: "Inizio ruolo come Segretario responsabile presso il Centro Oftalmologico, Corso alla Vittoria 103, Asti, Italia"
    },
    {
        data: "Marzo 2020",
        evento: "Sotto la mia guida, la compagnia è andata online per la prima volta, gestendo la realizzazione del sito web e garantendo la continuità operativa durante l'inizio della pandemia di COVID-19"
    },
    {
        data: "Maggio 2020",
        evento: "Responsabile del rebranding aziendale, incluso la creazione di un nuovo logo per rinnovare l'immagine della compagnia."
    },
    {
        data: "Agosto 2020",
        evento: "Leadership nelle operazioni di Front e Back Office, assicurando un servizio continuativo e adattando i processi organizzativi per rispondere alla situazione sanitaria"
    },
    {
        data: "Aprile 2021",
        evento: "Conclusione dell’esperienza professionale, pronto per affrontare il mio percorso informatico"
    }
];

const sunstarExperience = [
    {
        data: "Marzo 2018",
        evento: "Receptionist presso Sunstar Hotel Castello di Villa, Isola d’Asti, Italia"
    },
    {
        data: "Dicembre 2018",
        evento: "Trasferimento presso Sunstar Hotel Klosters, Klosters-Serneus, Svizzera"
    },
    {
        data: "Marzo 2019",
        evento: "Rientro in Italia e promozione ad Assistente del Direttore, con responsabilità su gestione turni e turnover del personale"
    },
    {
        data: "Novembre 2019",
        evento: "Conclusione dell'esperienza lavorativa in Sunstar Hotel dopo aver sviluppato competenze in accoglienza e gestione di team"
    }
];

const altenExperience = [
    {
        data: "Novembre 2021",
        evento: "Inizio ruolo come Junior Software Developer presso ALTEN Italia, cliente settore bancario"
    },
    {
        data: "Dicembre 2021",
        evento: "Completamento corso Scrum Master presso Alten Academy"
    },
    {
        data: "Gennaio 2022",
        evento: "Contributo alla migrazione da architettura monolitica a microservizi con Docker"
    },
    {
        data: "Ottobre 2022",
        evento: "Implementazione di compliance PCI DSS per la protezione dei dati sensibili"
    },
    {
        data: "Giugno 2023",
        evento: "Guidato l’onboarding di nuovi membri del team, fornendo supporto su strumenti e processi"
    },
    {
        data: "Gennaio 2024",
        evento: "Promozione a Software Developer"
    },
    {
        data: "Aprile 2024",
        evento: "Ottimizzazione delle prestazioni con scripting Unix e gestione avanzata database Oracle"
    },
    {
        data: "Maggio 2024",
        evento: "Partecipazione al progetto di migrazione del processore bancario da Nexi a Mastercard S.A."
    },
    {
        data: "To be continued",
        evento: "Contributo attivo a nuovi progetti, innovazioni e modernizzazione architetturale"
    }
];

const Society = () => {
    const pStoria = "Mi chiamo Edoardo Orta e sono un Software Developer con una solida esperienza nello sviluppo backend e frontend. Attualmente lavoro nel settore bancario, dove mi occupo della creazione di soluzioni per i pagamenti elettronici. Ho sviluppato applicazioni usando tecnologie come Java, Spring Boot, React e ho gestito la transizione a una struttura di microservizi tramite Docker e Kubernetes, concentrandomi su performance, sicurezza e scalabilità.\nIl mio percorso verso la programmazione è nato dalla passione per la tecnologia, ma le mie esperienze precedenti hanno arricchito la mia visione e capacità di problem-solving. Prima di immergermi nel mondo dello sviluppo software, ho avuto diverse esperienze professionali, dalla gestione di clienti in ambito alberghiero e medico, fino a ruoli di assistenza tecnologica a domicilio. In questi contesti, ho imparato il valore della precisione e dell’empatia nel lavoro, competenze che continuo a utilizzare anche nel campo tecnologico.\nSono costantemente affascinato dalle nuove frontiere dell'innovazione, come l'intelligenza artificiale, l'Internet of Things, i veicoli elettrici e la robotica. In questi settori vedo un futuro stimolante dove posso applicare e ampliare le mie competenze, lavorando a progetti che uniscono utilità e progresso.";
    const helloAdele = "I was wondering if after all these years you'd like to meet To go over everything They say that time's supposed to heal ya, but I ain't done much healing Hello, can you hear me? I'm in California dreaming about who we used to beWhen we were younger and freeI've forgotten how it felt before the world fell at our feet There's such a difference between us And a million miles Hello from the other side I must've called a thousand times To tell you I'm sorry for everything that I've done But when I call, you never seem to be home Hello from the outside At least I can say that I've tried To tell you I'm sorry for breaking your heart But it don't matter, it clearly doesn't tear you apart anymore Hello, how are you? It's so typical of me to talk about myself, I'm sorry I hope that you're well Did you ever make it out of that town where nothing ever happened? It's no secret that the both of us Are running out of time So hello from the other side (other side) I must've called a thousand times (thousand times) To tell you I'm sorry for everything that I've done But when I call, you never seem to be home Hello from the outside (outside) At least I can say that I've tried (I've tried) To tell you I'm sorry for breaking your heart But it don't matter, it clearly doesn't tear you apart anymore Ooh (lows, lows, lows, lows), anymore (Highs, highs, highs, highs) Ooh (lows, lows, lows, lows), anymore (Highs, highs, highs, highs) Ooh (lows, lows, lows, lows), anymore (Highs, highs, highs, highs) Anymore (lows, lows, lows, lows) Hello from the other side (other side) I must've called a thousand times (thousand times) To tell you I'm sorry for everything that I've done But when I call, you never seem to be home Hello from the outside (outside) At least I can say that I've tried (I've tried) To tell you I'm sorry for breaking your heart But it don't matter, it clearly doesn't tear you apart anymore"
    return (
        <main className="society">
            <IntroductoryParagraph title="Hello, It's me" backtitle={helloAdele} body={pStoria}></IntroductoryParagraph>
            <BiColumnComponents
                main={(

                    <SectionTitle title="ALTEN"></SectionTitle>
                )
                }
                classes="padded"
                side={

                    (<SectionTitle title="Software Developer" />)

                }></BiColumnComponents>
            <Timeline timestampsList={altenExperience} />
            <BiColumnComponents
                main={(

                    <SectionTitle title="COOA - Centro Ortottico Oculisto Asti"></SectionTitle>
                )
                }
                classes="padded"
                side={

                    (<SectionTitle title="Segretario" />)

                }></BiColumnComponents>
            <Timeline timestampsList={cooaExperience} />
            <BiColumnComponents
                main={(

                    <SectionTitle title="SUNSTAR HOTELS"></SectionTitle>
                )
                }
                classes="padded"
                side={

                    (<SectionTitle title="Receptionist" />)

                }></BiColumnComponents>
            <Timeline timestampsList={sunstarExperience} />



        </main>
    );
};

export default Society;
