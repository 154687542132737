import { Links } from '../utils/Constants';
import './../css/components/social-icons.scss';

function SocialIcons(props) {
    return (<div className="social-icons">
        <div className="icon">
            <a href={Links.SOCIAL.INSTAGRAM}>
                <i className="fa-brands fa-instagram"></i>
            </a>
        </div>
        <div className="icon">
            <a href={Links.SOCIAL.YOUTUBE}>
                <i className="fa-brands fa-youtube"></i>
            </a>
        </div>
        <div className="icon">
            <a href={Links.SOCIAL.LINKEDIN}>
                <i className="fa-brands fa-linkedin"></i>
            </a>

        </div>
    </div>);
}
export default SocialIcons;