import './../../css/components/hero-text-scrollable.scss';
import linksJson from './../../assets/files/jsons/links.json';
import { Link } from 'react-router-dom';


function HeroTextScrollable(props) {
    const greekText = [
        "Τῶν ὄντων τὰ μέν ἐστιν ἐφ᾿ ἡμῖν, τὰ δὲ οὐκ ἐφ᾿ ἡμῖν. ἐφ᾿ ἡμῖν μὲν ὑπόληψις, ὁρμή, ὄρεξις, ἔκκλισις καὶ ἑνὶ λόγῳ ὅσα ἡμέτερα ἔργα·",
        "οὐκ ἐφ᾿ ἡμῖν δὲ τὸ σῶμα, ἡ κτῆσις, δόξαι, ἀρχαὶ καὶ ἑνὶ λόγῳ ὅσα οὐχ ἡμέτερα ἔργα. καὶ τὰ μὲν ἐφ᾿ ἡμῖν ἐστὶ φύσει ἐλεύθερα, ἀκώλυτα, ἀπαραπόδιστα,",
        "τὰ δὲ οὐκ ἐφ᾿ ἡμῖν ἀσθενῆ, δοῦλα, κωλυτά, 3ἀλλότρια. μέμνησο οὖν, ὅτι, ἐὰν τὰ φύσει δοῦλα ἐλεύθερα οἰηθῇς καὶ τὰ ἀλλότρια ἴδια, ἐμποδισθήσῃ, πενθήσεις, ταραχθήσῃ, μέμψῃ ",
        "καὶ θεοὺς καὶ ἀνθρώπους, ἐὰν δὲ τὸ σὸν μόνον οἰηθῇς σὸν εἶναι, τὸ δὲ ἀλλότριον, ὥσπερ ἐστίν, ἀλλότριον, οὐδείς σε ἀναγκάσει οὐδέποτε, οὐδείς σε κωλύσει, ",
        "οὐ μέμψῃ οὐδένα, οὐκ ἐγκαλέσεις τινί, ἄκων πράξεις οὐδὲ ἕν, ἐχθρὸν οὐχ ἕξεις, οὐδείς σε βλάψει, οὐδὲ γὰρ βλαβερόν τι πείσῃ."
    ];



    return (
        <div className="container-fix hero">
            <div className="text-background-container">
                {greekText.map((line, index) => (
                    <p key={index} className={`scroll-line ${index % 2 === 0 ? 'scroll-right' : 'scroll-left'}`}>
                        {line}
                    </p>
                ))}
            </div>

            <div className="text-container">
                <h1 className="text-top">Edoardo</h1>
                <h2 className="text-middle">
                    {linksJson.map((link, linkIndex) => (
                        <span key={link.id}>
                            <Link to={link.path} className="link">
                                {link.shownName}
                            </Link>
                            {/* Separatore tra link principali e sublink */}
                            {link.subLinks && link.subLinks.map((subLink, subIndex) => (
                                <span key={subLink.id}>
                                    · <Link to={link.path+subLink.path} className="sublink">
                                        {subLink.shownName}
                                    </Link>
                                </span>
                            ))}
                            {linkIndex < linksJson.length - 1 && ' · '}
                        </span>
                    ))}
                </h2>
                <h1 className="text-down">Orta</h1>
            </div>
        </div>
    );
}

export default HeroTextScrollable;

