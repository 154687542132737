import Hr from "../../components/basics/Hr";

const Devlog = () => {
    return (
        <main class="mainBody container">
            <Hr></Hr>
            <blockquote class="blockquote">
                <p class="mb-0">Everyone deserves his own place on the internet.</p>
                <br />
                <footer class="blockquote-footer">Someone famous on the internet.</footer>
            </blockquote>
            <Hr></Hr>
            <div class="row hero textBox" id="whyThisWebsite">
                <h2>Why I made this website</h2>
                <p>
                    So I started in 2021 creating this personal website as an online CV, but
                    soon I realized I liked it and wanted to do more, to challenge myself
                    creating new things I've ever known nothing about.
                </p>
                <p>
                    One of the criteria to host the things you see on this website is to be
                    fully understood and handwritten by me. Not copypasting anything, that's
                    the same reason edorta.it is pretty simple right now; but I assure you,
                    one day it will be <strong>gigantic!</strong>
                </p>
            </div>
            <Hr></Hr>

            <div class="row section textBox" id="whatsComing">
                <h2>What's coming up next</h2>
                <p>
                    I have big plans for the coming future, I have made a list of the things I
                    would like to upload, they will pop up in the future not necessarily in
                    the order you see written here:
                </p>
                <ul>
                    <li>Create a page with personal data/contact me</li>
                    <li>
                        Create a page about my working experiences explaining more about it
                    </li>
                    <li>Rework of the menu and links (which are not easily understable)</li>
                    <li>Rework of the present social icons</li>
                    <li>Adding metatags</li>
                    <li>Creating a page about languages</li>
                    <li>Adding a query for tablets</li>
                    <li>Scanning my certificates and uploading them</li>
                </ul>
            </div>
            <Hr></Hr>
            <section class="row section textBox">
                <article>
                    <div class="vl"></div>
                    <div class="textBox">
                        <h2>Mk 1.2</h2>
                        <p>06/27/2021</p>

                        <ol>
                            <li>
                                Introducing:
                                <ul>
                                    <li>
                                        the Developer Journal, a sort of dev diaries
                                        <p>Few screeshots about this new page</p>
                                        <div class="imgComparison">
                                            <div class="imgWrapper">
                                                <img src="/assets/dj1.PNG" alt="" />
                                            </div>
                                            <div class="imgWrapper">
                                                <img src="/assets/dj2.PNG" alt="" />
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        A logo redesign, with the material UI:
                                        <div class="imgComparison">
                                            <div class="imgWrapper">
                                                <p>Before</p>
                                                <img src="/assets/logoQR.jpg" alt="" />
                                            </div>
                                            <div class="imgWrapper">
                                                <p>After</p>

                                                <img src="/assets/Orta2.PNG" style={{ maxWidth: "100%" }} alt="" />
                                            </div>
                                        </div>
                                    </li>
                                    <li>A work-in-progress bar</li>
                                    <li>a mobile first programming</li>
                                </ul>
                            </li>
                            <li>
                                Fixing:
                                <ul>
                                    <li>A few visualization bugs</li>
                                    <li>Some typos</li>
                                    <li>Errors in data display</li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                </article>
                <article class="row">
                    <div class="vl"></div>
                    <div class="textBox">
                        <h2>Mk 1.1</h2>
                        <p></p>
                        <ol>
                            <li>
                                Introducing:
                                <ul>
                                    <li>
                                        The new home page and complete overhaul of the old website.

                                        <p>Few screeshots about this new page</p>
                                        <div class="imgComparison">
                                            <div class="imgWrapper">
                                                <img src="/assets/homepagemk1.PNG" alt="" />
                                            </div>
                                            <div class="imgWrapper">
                                                <img src="/assets/homepagemk1-1.PNG" alt="" />
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        A new logo, made with these new color palette:
                                        <div class="imgComparison" id="colorsPalette">
                                            <div class="imgWrapper">
                                                <img src="/assets/logo_large.png" alt="" />
                                            </div>
                                            <div class="imgWrapper" id="colors">
                                                <div class="color" id="blue">#489bfa</div>
                                                <div class="color" id="green">#96bf91</div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                </article>
                <article>
                    <div class="vl"></div>
                    <div class="textBox">
                        <h2>Mk 1.0</h2>
                        <p></p>
                        <ol>
                            <li>
                                Introducing:
                                <ul>
                                    <li>
                                        First time online, pretty basic website.

                                        <p>Few screeshots about the old page</p>
                                        <div class="imgComparison">
                                            <div class="imgWrapper">
                                                <img src="/assets/homepageVs1.PNG" alt="" />
                                            </div>
                                            <div class="imgWrapper">
                                                <img src="/assets/homepageVs1-1.PNG" alt="" />
                                            </div>
                                        </div>
                                    </li>
                                    <li>Going online with google cloud</li>
                                    <li>Basic layout</li>
                                </ul>
                            </li>
                        </ol>
                    </div>
                </article>
            </section>
            <Hr></Hr>
        </main>

    )
}


export default Devlog;