import BiColumnImageText from '../components/BiColumnImageText';
import PagesEnum from '../model/PagesEnum';
import logocuba from './../assets/images/pages/hobby/cubalibri.png';
import society from "./../assets/images/dnd/gypsiadina.webp";
import './../css/home.scss';
// import HeroicText from '../components/basics/HeroicText';

const Hobbies = () => {
  return (
    <main>
      <BiColumnImageText text={PagesEnum.CUBALIBRI.page} link={PagesEnum.CUBALIBRI.link} img={logocuba}></BiColumnImageText>
      <BiColumnImageText text={PagesEnum.DND.page} link={PagesEnum.DND.link} img={society}></BiColumnImageText>


    </main>

  );
};

export default Hobbies;