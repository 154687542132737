import ModelViewer from './basics/ModelViewer';
import earthModel from './../assets/files/3d/Earth.glb';
import BiColumnComponents from './BiColumnComponents';
import SectionTitle from './basics/SectionTitle';

function Earth3dModel() {
    return (
        <div className="container-fluid">
            <div className='row'>
           <ModelViewer  modelSrc={earthModel} rotationSpeed="40deg" autoRotateDelay="500" />
                
           </div>
        </div>
    );
}

export default Earth3dModel;