import Login from "../components/Login";

const Dashboard = () => {
    return (
        <main className="dashboard">
            <Login></Login>
        </main>
    );
};

export default Dashboard;