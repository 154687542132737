import React from 'react';
import Timestamp from './basics/Timestamp.js';
import './../css/components/timeline.scss';

const timestampsJson = [
    {
        data: "Aprile 2022",
        evento: "Cuba Libri nasce come collettivo"
    },
    {
        data: "Novembre 2022",
        evento: "Cuba Libri si costituisce associazione culturale ricreativa"
    },
    {
        data: "Luglio 2023",
        evento: "Cuba Libri realizza la rassegna letteraria Decanter con Fandango"
    },
    {
        data: "Aprile 2024",
        evento: "Cuba Libri lancia Cuba Libri Podcast e Cuba Song"
    },
    {
        data: "Luglio 2024",
        evento: "Cuba Libri lancia il sito cubalibri.it"
    },
    {
        data: "To be continued",
        evento: "Cuba Libri diffonde cultura e organizza nuovi eventi"
    }
];

function Timeline({ timestampsList = timestampsJson }) {
    return (
        <div className='container-fluid timeline'>
            {timestampsList.map((timestamp, index) => (
                <Timestamp timestamp={timestamp} key={index} />
            ))}
        </div>
    );
}

export default Timeline;
