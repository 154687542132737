import React from "react";
import { Row } from "reactstrap";
import "../../css/components/ProjectCarousel.scss";
import ActionCard from "./ActionCard";
import SectionTitle from "./SectionTitle";

const projects = [
    {
        title: "CubaLibri.it",
        paragraph: "Webapp per un'associazione culturale che mostra i libri letti e gli eventi, in react js, hostata su Google Firebase.",
        link: { shownText: "Coming soon", path: "https://cubalibri.it" }
    },
    {
        title: "CascinaMarcella.it",
        paragraph: "Webapp per un'azienda agricola che mostra i suoi prodotti, in Java sviluppata con Bootstrap e Spring, MongoDB.",
        link: { shownText: "Esplora il sito", path: "https://cascinamarcella.it" }
    },
    {
        title: "Edorta.it",
        paragraph: "Il mio sito personale, evoluto da HTML puro ad Angular e ora realizzato in React JS.",
        link: { shownText: "Esplora il sito", path: "https://edorta.it" }

    },
    {
        title: "GDOleodinamicaCanova.it",
        paragraph: "Sito vetrina per un negozio di attrezzature agricole e oleodinamiche.",
        link: { shownText: "Esplora il sito", path: "https://gdoleodinamicacanova.it" }
    },
    {
        title: "Algotrading",
        paragraph: "Integrazione di un algoritmo di trading in una piattaforma di trading bancaria.",
    },
];

function ProjectCarousel() {
    return (
        <div className="container-fluid">
            <SectionTitle title="I miei progetti" />

            <Row className="flex-nowrap scroll-container">
                {projects.map((project, index) => (
                    <ActionCard className="project-card" key={index} {...project} />
                ))}
            </Row>
        </div>

    );
}

export default ProjectCarousel;
