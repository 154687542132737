import BiColumnImageText from '../components/BiColumnImageText';
import PagesEnum from '../model/PagesEnum';
import mockups from "./../assets/images/pages/tech/mockups.png";
import './../css/home.scss';
// import HeroicText from '../components/basics/HeroicText';
import BiColumnComponents from '../components/BiColumnComponents';
import Accordion from '../components/basics/Accordion';
import HeroTextScrollable from '../components/basics/HeroTextScrollable';
import { DatiFiscali } from '../utils/Constants';
import certificationJson from './../assets/files/jsons/certification.json';
import Earth3dModel from '../components/Earth3dModel';


const contenutoSideImage = (<>
  <div className='contatto'>
    <h2 className='nome-contatto'>email</h2>
    <a href={`mailto:${DatiFiscali.EMAIL}`}><p className='valore-contatto'>{DatiFiscali.EMAIL}</p></a>
  </div>
  <div className='contatto'>
    <h2 className='nome-contatto'>cellulare</h2>
    <a href={`callto:${DatiFiscali.CELLULARE}`}><p className='valore-contatto'>{DatiFiscali.CELLULARE}</p></a>
  </div>
</>
);


const TechProjects = () => {
  return (
    <main>
      <Earth3dModel />
      <BiColumnImageText text={PagesEnum.PROJECTS.page} link={PagesEnum.PROJECTS.link} img={mockups}></BiColumnImageText>
      <BiColumnComponents main={contenutoSideImage} classes={"padded"} side={(<><h1>Certificati</h1><Accordion data={certificationJson} /></>)}></BiColumnComponents>

    </main>

  );
};

export default TechProjects;