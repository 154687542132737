export const DatiFiscali = {
    NOME_UFFICIALE: "Edoardo Orta",
    CODICE_FISCALE: "",
    EMAIL: "edoardo.orta.17@gmail.com",
    SEDE_LEGALE: "",
    CELLULARE: "351 8026936",
    IBAN: ""
}

export const Links = {
    SOCIAL: {
        TELEGRAM: "",
        YOUTUBE: "https://www.youtube.com/@Backnews97",
        INSTAGRAM: "https://www.instagram.com/eothedev/",
        SPOTIFY: "",
        LINKEDIN: "https://www.linkedin.com/in/edoardo-orta-396097123/"
    }
}
export const Colors = {
    MAIN_LIGHT: "#FFE5CF",
    SECONDARY_LIGHT: "#B6DB49",
    MAIN_DARK: "black",
    MAIN_ACCENT: "#d72377",
    SECONDARY_DARK: "#a99889",
    GRAPH1: "#FFE5CF",    // Colore principale (Tonalità pastello arancio)
    GRAPH2: "#FFCFB2",    // Analogico chiaro del principale
    GRAPH3: "#FFC299",    // Analogico più scuro del principale
    GRAPH4: "#FFD4B3",    // Variabile calda del principale
    GRAPH5: "#FFC48D",    // Tonalità arancio più intensa

    GRAPH6: "#B6DB49",    // Colore secondario (Verde vivace)
    GRAPH7: "#A3C53F",    // Analogico più scuro del secondario
    GRAPH8: "#90B335",    // Verde muschio scuro (Complementare del secondario)
    GRAPH9: "#D3E96A",    // Verde chiaro lime
    GRAPH10: "#81A529",   // Verde bosco scuro

    GRAPH11: "#d72377",   // Colore accent (Rosa intenso)
    GRAPH12: "#e53c8a",   // Rosa analogico più chiaro
    GRAPH13: "#ba205e",   // Analogico più scuro dell'accent
    GRAPH14: "#e83697",   // Rosa fucsia brillante
    GRAPH15: "#ff52a6",   // Variante rosa acceso chiaro

    GRAPH16: "#FFD6F2",   // Complementare chiaro dell'accent (rosa pastello)
    GRAPH17: "#EAF0D6",   // Complementare chiaro del secondario (verde menta)
    GRAPH18: "#FFECD5",   // Complementare chiaro del principale (avorio chiaro)
    GRAPH19: "#DCE46B",   // Giallo lime brillante (Complementare secondario)
    GRAPH20: "#FFB1C4"    // Complementare dell'accent (rosa salmone)


}

