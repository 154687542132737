import React from 'react';
import '../css/components/basics/skillset.scss';



function Skillset({ skills }) {
  return (
    <div className="skillset">
      {skills.map((category, index) => (
        <div key={index} className="resume-skill-item">
          <h6 className="resume-skills-cat">{category.category}</h6>
          <p className="skills-list">
            {category.skills.join(', ')}
          </p>
        </div>
      ))}
    </div>
  );
}

export default Skillset;
