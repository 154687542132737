import AutoPlayAudio from '../components/basics/AutoPlayAudio';
import ButtonLink from '../components/basics/ButtonLink';
import Hr from '../components/basics/Hr';


const audio = (<div><AutoPlayAudio /></div>);

const linkHome = (<ButtonLink link={{ "path": "/home" }} ></ButtonLink>);
const Error404 = () => {
    return (
        <main className='error-404 container'>
                <Hr></Hr>
                <div class="row">
                    <div class="col">
                        <h1>Pagina non trovata!</h1>
                        <p class="lead">
                            Attenzione: Errore 404, pagina non trovata
                            <i class="fa-regular fa-file-excel fa-spin fa-spin-reverse" ></i>
                            <br />
                            <ButtonLink link={{path:"/",shownText:"torna alla home"}}/>
                            </p>
                    </div>
                </div>
                <Hr></Hr>


        </main>
    );
};

export default Error404;