import React from 'react';
import "../css/bicolumn.scss";
import useMediaQuery from "../utils/useMediaQuery";

function BiColumnComponents(props) {
    const { tablet, desktop } = useMediaQuery();  // Call the hook here
    const { side, main, classes } = props;

    const isWide = tablet || desktop;

    // Costruzione della stringa delle classi
    const combinedClasses = `text-center alternating ${isWide ? 'w-50' : ''} ${classes ? classes : ''}`;

    return (
        <div className="container-fluid bicolumn">
            <div className="row alternating-row">
                <div className={combinedClasses}>
                    {side}
                </div>
                <div className="col alternating-text">
                    {main}
                </div>
            </div>
        </div>
    );
}

export default BiColumnComponents;
