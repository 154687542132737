import './../../css/components/section-title.scss';


function SectionTitle(props) {
  

  return (
    <div className='row section-title' id={props.id}>
      <div className='col'>
        <h1>{props.title}</h1>
      </div>
    </div>
  )
};

export default SectionTitle;