
// Images
import { Row } from 'reactstrap';
import ActionCard from '../components/basics/ActionCard';
import HeroTextScrollable from '../components/basics/HeroTextScrollable';
import PagesEnum from '../model/PagesEnum';

const HomePage = () => {
  return (
    <main>
      <HeroTextScrollable></HeroTextScrollable>
      <div className="container-fluid">
        <Row className="flex-nowrap scroll-container">
          {/* Define ActionCard components dynamically for each section in PagesEnum */}
          <ActionCard className="page-card" {...{
            title: PagesEnum.PROJECTS.page.title,
            paragraph: PagesEnum.PROJECTS.page.paragraph,
            link: PagesEnum.PROJECTS.link
          }} />
          <ActionCard className="page-card" {...{
            title: PagesEnum.WHO_I_AM.page.title,
            paragraph: PagesEnum.WHO_I_AM.page.paragraph,
            link: PagesEnum.WHO_I_AM.link
          }} />
          <ActionCard className="page-card" {...{
            title: PagesEnum.POLITICS.page.title,
            paragraph: PagesEnum.POLITICS.page.paragraph,
            link: PagesEnum.POLITICS.link
          }} />
          <ActionCard className="page-card" {...{
            title: PagesEnum.HOBBIES.page.title,
            paragraph: PagesEnum.HOBBIES.page.paragraph,
            link: PagesEnum.HOBBIES.link
          }} />
          <ActionCard className="page-card" {...{
            title: PagesEnum.CONTACTS.page.title,
            paragraph: PagesEnum.CONTACTS.page.paragraph,
            link: PagesEnum.CONTACTS.link
          }} />
        </Row>
      </div>
    </main>
  );
};

export default HomePage;
