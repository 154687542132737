import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import '../css/footer.scss';
import cookiePolicyContent from "../template/cookie-policy.html";
import privacyPolicyContent from "../template/privacy-policy.html";
import SocialIcons from './SocialIcons';
import orta from '../assets/images/components/footer/Orta.PNG'
import { DatiFiscali } from '../utils/Constants';

function Footer(props) {
    const [modal, setModal] = useState(false);
    const [modalContent, setModalContent] = useState('');

    const toggle = () => setModal(!modal);

    const handlePolicyClick = (policyType) => {
        if (policyType === 'cookie') {
            setModalContent(cookiePolicyContent);
        } else if (policyType === 'privacy') {
            setModalContent(privacyPolicyContent);
        }
        toggle();
    };

    return (
        <footer className='container-fluid' id='footer'>
            <div className='row'>
                <div className='col-md-4 text-center'>
                    <ul>
                        <li>
                            <span className='description'></span> <span className='value'>Crediti</span>
                        </li>
                        <li>
                            <span className='description'></span> <span className='value'>Questo sito è stato realizzato da Edoardo Orta con l'uso di React.js e Reactstrap (Bootstrap per react) per il frontend.
                            </span>
                        </li>
                        <li>
                            <span className='description'></span> <span className='value'>Tutte le foto sono di proprietà di Edoardo Orta.</span>
                        </li>
                    </ul>
                </div>
                <div className='col-md-4 text-center'>
                    <SocialIcons />
                </div>
                <div className='col-md-4 text-center'>
                    <ul>
                        <li>
                            <span>
                                <a href='https://www.edorta.it'>
                                    <img loading='lazy' src={orta}></img>
                                </a></span>
                        </li>
                        <li>
                            <span className='description'></span> <span className='value'>Grazie di essere arrivato fino a qui!</span>
                        </li>
                        <li>
                            <span className='description'></span> <span className='value'>Sentiti libero di contattarmi o lasciarmi un feedback!</span>
                        </li>
                        <li>
                            <span className='description'>email:</span> <span className='value'><a href={`mailto:${DatiFiscali.EMAIL}`}>{DatiFiscali.EMAIL}</a></span>
                        </li>
                        <li>
                            <span className='description'>Cell:</span> <span className='value'><a href={`callto:${DatiFiscali.CELLULARE}`}>{DatiFiscali.CELLULARE}</a></span>
                        </li>
                    </ul>
                </div>
            </div>
            <div id='subfooter'>
                <div className='text-center'>
                    Scopri la <a onClick={() => handlePolicyClick('cookie')}>cookie policy</a> e la <a onClick={() => handlePolicyClick('privacy')}>privacy policy</a>.
                </div>
                <Modal isOpen={modal} toggle={toggle} fullscreen>
                    <ModalHeader toggle={toggle}>Cookie Policy e Privacy</ModalHeader>
                    <ModalBody>
                        {/* Utilizza il contenuto del file HTML qui */}
                        <div dangerouslySetInnerHTML={{ __html: modalContent }} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color='success' onClick={toggle}>
                            Ho preso visione
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        </footer>
    );
};

export default Footer;
