import React from 'react';

function GdprConsent() {
  const today = new Date().toLocaleDateString('it-IT'); // Formatta la data come gg/mm/aaaa

  return (
    <div className='gdpr'>
      Autorizzo in data {today} al trattamento dei miei dati personali in base all’art. 13 GDPR 679/16.
    </div>
  );
}

export default GdprConsent;
