import React from 'react';
import '../css/components/basics/experience.scss';


function Experience(props) {
  const experiences = props.array;

  return (
    <div className="resume-timeline position-relative">
      {experiences.map((exp, index) => (
        <article className="resume-timeline-item position-relative pb-5" key={index}>
          <div className="resume-timeline-item-header" style={{width:"90%"}}>
            <h5 className="resume-position-title font-weight-bold mb-1">{exp.title}</h5>
            <div className="resume-company-name ml-auto">{exp.company}</div>
            <div className="resume-position-time">{exp.time}</div>
          </div>
          <div className="resume-timeline-item-desc">
            <p>{exp.location}</p>
            {exp.description && (
              <p>
                {exp.description.map((item, idx) => (
                  <span key={idx}>{item}<br/></span>
                  
                ))}
              </p>
            )}
          </div>
        </article>
      ))}
    </div>
  );
}

export default Experience;
