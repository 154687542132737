import { Card, CardBody, CardText, CardTitle } from "reactstrap";
import ButtonLink from "./ButtonLink";
import CopyButton from "./CopyButton.js"; // Assumo che tu abbia un componente CopyButton
import "./../../css/components/card.scss"

function ActionCard(props) {
    const { title, paragraph, link, textCopy } = props;
    return (
        <Card className="text-center">
            <CardBody>
                <CardTitle tag="h5">
                    {title}
                </CardTitle>
                <CardText>
                    {paragraph}
                </CardText>
                {link && (
                    <ButtonLink link={link}>
                        Vai al link
                    </ButtonLink>
                )}
                {textCopy && (
                    <CopyButton text={textCopy}>
                        Copia testo
                    </CopyButton>
                )}
            </CardBody>
        </Card>
    );
}

export default ActionCard;
