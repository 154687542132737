import React, { useState } from 'react';
import { Button } from 'reactstrap';
import './../../css/components/button.scss';
import './../../css/components/notification.scss';

function CopyButton(props) {
    const [showNotification, setShowNotification] = useState(false);

    const copyToClipboard = (text) => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text)
                .then(() => {
                    setShowNotification(true);
                    setTimeout(() => setShowNotification(false), 3000);
                })
                .catch(err => {
                    alert("Errore durante la copia del testo: ", err);
                });
        } else {
            const textArea = document.createElement("textarea");
            textArea.value = text;
            document.body.appendChild(textArea);
            textArea.select();
            try {
                document.execCommand("copy");
                setShowNotification(true);
                setTimeout(() => setShowNotification(false), 3000);
            } catch (err) {
                alert("Errore durante la copia del testo: ", err);
            }
            document.body.removeChild(textArea);
        }
    };

    return (
        <>
            {props.text ? (
                <Button
                    className={props.classes ? `${props.classes} btn button-link` : "btn btn-primary button-link"}
                    onClick={() => copyToClipboard(props.text.copyText)}
                >
                    {props.text.shownText || 'Testo non generato correttamente!'} <i className='fas fa-copy'></i>
                </Button>
            ) : null}

            {showNotification && (
                <div className="notification">
                    Testo copiato negli appunti!
                </div>
            )}
        </>
    );
}

export default CopyButton;
