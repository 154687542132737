import BiColumnImageText from '../components/BiColumnImageText';
import noimoderati from "../assets/images/pages/politics/logo noi moderati.png";

import './../css/home.scss';

const Politics = () => {
  return (
    <main>
      <BiColumnImageText
        text={{
          title: "Elezioni Comunali 2022",
          paragraph: "Scopri le mie idee e proposte per il futuro di Asti nel mio volantino elettorale. Leggi di più su come insieme possiamo migliorare la nostra città!"
        }}
        link={{
          shownText: "Scopri il mio programma",
          path: "/politics/elections22"
        }}
        img={noimoderati}
      />
    </main>

  );
};

export default Politics;