import React from 'react';
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate per la navigazione programmatica
import './../../css/components/button.scss';

function ButtonLink(props) {
    const navigate = useNavigate(); // Usa useNavigate per la navigazione programmatica
    
    const handleClick = () => {
        if (props.link) {
            const { path } = props.link;

            // Controlla se il link è un mailto, tel, o altro schema speciale
            if (path.startsWith('mailto:') || path.startsWith('tel:') || path.startsWith('sms:') || path.startsWith('http:') || path.startsWith('https:')) {
                window.location.href = path; // Usa window.location.href per i link speciali
            } else {
                // Naviga verso il percorso specificato se non è uno schema speciale
                navigate(path || '/'); 

                setTimeout(() => {
                    // Scorri alla sezione con l'ID specificato dopo un breve ritardo
                    const element = document.getElementById(props.link.anchor);
                    if (element) {
                        element.scrollIntoView({ behavior: 'smooth' });
                    } else {
                        // Se l'ancora non è presente, scorri all'inizio della pagina
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    }
                }, 100); // Ritardo di 100ms per assicurarti che la navigazione sia completata
            }
        }
    };

    return (
        <>
            {/* Utilizza il componente Button per la navigazione */}
            {props.link ? (
                <Button
                    className={props.classes ? props.classes + " btn button-link" : "btn btn-primary button-link"}
                    onClick={handleClick} // Usa handleClick per gestire la navigazione
                >
                    {props.link.shownText || 'Vai alla home!'}
                </Button>
            ) : null}
        </>
    );
}

export default ButtonLink;
