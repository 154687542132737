import React from 'react';
import '@google/model-viewer'; // Assicurati di aver importato il pacchetto del <model-viewer>

// Definisci un componente per mostrare un modello 3D della Terra che ruota
function ModelViewer({
    modelSrc = 'defaultModel.glb',    // Percorso del modello 3D con valore di default
    rotationSpeed = "30deg",          // Velocità di rotazione personalizzabile
    autoRotateDelay = "1000",         // Ritardo di auto-rotazione
    exposure = "1.2",                 // Esposizione della scena
    className = "col",                // Classe CSS per lo styling
}) {
    return (
        <model-viewer
            min-height="20vh"
            width="100%"
            className='col' style={{ height: "60vh" }}
            src={modelSrc}
            id="globe"
            auto-rotate
            rotation-per-second={rotationSpeed}
            camera-controls
            auto-rotate-delay={autoRotateDelay}
            environment-image="neutral"
            exposure={exposure}>
        </model-viewer>
    );
}

export default ModelViewer;
