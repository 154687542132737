import React, { useState } from 'react';
import {
    Accordion as ReactstrapAccordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
} from 'reactstrap';
import '../../css/components/accordion.scss';

function Accordion({ data }) {
    const [open, setOpen] = useState(null);

    const toggle = (id) => {
        if (open === id) {
            setOpen(null);
        } else {
            setOpen(id);
        }
    };

    return (
      
            <ReactstrapAccordion open={open} toggle={toggle}>
                {data.map((item, index) => (
                    <AccordionItem key={index} >
                        <AccordionHeader targetId={index.toString()} >{item.title}</AccordionHeader>
                        <AccordionBody accordionId={index.toString()} >
                            {item.content}
                        </AccordionBody>
                    </AccordionItem>
                ))}
            </ReactstrapAccordion>
    );
}

export default Accordion;
