import './../../css/components/timeline.scss';

function Timestamp(props) {

    return (
        <>
            {
                props.timestamp ? (
                    <div className="timestamp row">
                        <div className="col-md-6 text-right">
                            {props.timestamp.data ? props.timestamp.data : "data"}
                            <hr className='accent-line'></hr>
                        </div>
                        <div className="col-md-6 text-left">
                            {props.timestamp.evento ? props.timestamp.evento : "evento"}
                        </div>
                    </div>) : null
            }
        </>
    )
}

export default Timestamp;