
function YoutubePlayer(props) {

    return (<>
        {props.url ? (
            <iframe
                className="player youtube-player"
                src={props.url}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen></iframe>


        ) : (<></>)
        }
    </>

    );
}
export default YoutubePlayer;