import './../../css/components/introductory-paragraph.scss';


function IntroductoryParagraph(props) {
  const { title, body, backtitle } = props;

  return (
    <div className="container-fluid introductory-paragraph">
      <div className="row">
        <div className="col">
          <h2 className="text-center">
            {title || ''}
          </h2>
          <div className='backtitle-container'>
            <h2 className="backtitle"> {backtitle || ''}</h2>
          </div>
          <p className="text-justify">
            {body ||
              'Il sito non raccoglie cookies fuorché quelli necessari, ovvero tecnici, che ne garantiscono il corretto funzionamento. La seguente informativa viene rilasciata ai sensi del Regolamento GDPR UE 2016/679 art. 13'}
          </p>
        </div>
      </div>
    </div>
  );
}

export default IntroductoryParagraph;
